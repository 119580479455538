<template>
  <div>
    <l-button
      v-auth="'AUTH_APP_INSTANCE-SAVE'"
      type="primary"
      class="c-top_right_btn"
      @click="editRow()"
    >
      新建系统实例
    </l-button>
    <!-- 列表区域-STR -->
    <l-table-common
      :data-source="tableData"
      @change="search"
    >
      <l-table-column
        data-index="id"
        title="流水号"
      />
      <l-table-column
        data-index="name"
        title="实例名字"
      />
      <l-table-column
        data-index="code"
        title="英文编码"
      />
      <l-table-column
        data-index="appName"
        title="系统名字"
      />
      <l-table-column title="类型">
        <template slot-scope="scope">{{ scope.type === 0?'内部系统':'外部系统' }}</template>
      </l-table-column>

      <l-table-column>
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            v-auth="'AUTH_APP_INSTANCE-SAVE'"
            type="link"
            @click="editRow(scope)"
          >
            编辑
          </l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表区域-END -->

    <!-- 保存记录-STR -->
    <l-modal
      v-model="editDialog.show"
      :title="editDialog.data.id?'修改系统实例':'添加系统实例'"
      :confirm-loading="editDialog.save"
      width="600px"
      cancel-text="取消"
      ok-text="确认"
      @ok="saveDialog"
    >
      <l-form-model
        ref="form"
        :rules="rules"
        :model="editDialog.data"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 16 }"
      >
        <l-form-model-item label="系统" prop="appId" required>
          <l-select
            v-model="editDialog.data.appId"
            show-search
            placeholder="请输入系统名字"
            :filter-option="false"
            @search="remoteMethod"
            @focus="remoteMethod()"
          >
            <l-spin v-if="loading" slot="notFoundContent" size="small" />
            <l-select-option
              v-for="item in apps"
              :key="item.id"
              :value="item.id"
            >
              {{ item.cnName }}
            </l-select-option>
          </l-select>
        </l-form-model-item>
        <l-form-model-item label="实例名字" prop="name" required>
          <l-input
            v-model="editDialog.data.name"
            placeholder="请输入实例名字"
          />
        </l-form-model-item>
      </l-form-model>
    </l-modal>
    <!-- 保存记录-END -->
  </div>
</template>

<script>
export default {
  name: 'AuthAppInstanceList',
  data() {
    return {
      apps: [],
      loading: false,
      tableData: {
        isLoading: false,
        total: 0,
        page: 1,
        size: 10,
        list: []
      },
      editDialog: {
        data: {
          appId: '',
          name: ''
        },
        save: false,
        show: false
      },
      rules: {
        appId: [{ required: true, message: '请选择系统', trigger: 'change' }],
        name: [{ required: true, message: '请输入实例名字', trigger: 'change' }]
      }
    }
  },
  mounted() {
    this.search(1)
  },
  methods: {
    async search(page) {
      page = (page && page.current) || page || this.tableData.page || 1
      this.tableData.isLoading = true

      try {
        const data = await this.$store.dispatch('auth/authAppInstanceList', {
          page: page,
          size: this.tableData.size
        })
        this.tableData.list = data.list || []
        this.tableData.total = data.count
        this.tableData.page = page
        this.tableData.isLoading = false
      } catch (e) {
        this.tableData = this.$options.data().tableData
      }
    },
    resetForm() {
      this.search(1)
    },
    editRow(row) {
      row = row || {}
      this.editDialog.data = JSON.parse(JSON.stringify(row))
      for (const key in this.editDialog.errmsg) {
        this.editDialog.errmsg[key] = ''
      }
      this.remoteMethod()
      this.editDialog.show = true
    },
    async remoteMethod(query) {
      this.loading = true
      try {
        const data = await this.$store.dispatch('auth/authAppList', {
          quickSearch: query,
          type: 1,
          size: 1000
        })
        this.apps = data.list || []
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    async saveDialog() {
      this.editDialog.save = true
      try {
        await this.$refs.form.validate()
        this.$store.dispatch('auth/authAppInstanceSave', {
          id: this.editDialog.data.id,
          appId: this.editDialog.data.appId,
          name: this.editDialog.data.name
        })
        this.editDialog = this.$options.data().editDialog
        this.search()
      } catch (e) {
        this.editDialog.save = false
      }
    }
  }
}
</script>

<style lang="scss">
.system-index-btns {
  text-align: right;
  & i {
    font-size: 12px;
    margin-right: 8px;
    vertical-align: top;
  }
  & span {
    display: inline-block;
    vertical-align: top;
  }
}
.device-info {
  .device-info-body {
    margin: 15px 15px 70px;
  }
  .el-dialog__header {
    border-bottom: 1px solid $l-border-base;
  }
  .info-line {
    margin-bottom: 10px;
    span {
      display: inline-block;
      padding: 5px 0;
      color: $l-text-primary;
      font-size: 14px;
      line-height: 24px;
    }
    span.name {
      min-width: 110px;
      line-height: 24px;
    }
  }
}
</style>
